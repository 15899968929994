import logo from '../../assets/images/agalogo.png'

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  changePage(page, e) {
    e.preventDefault()
    this.props.master.setState({page: page, mobileNav: false})
    history.pushState({page: page}, null, page);
    if (window.registration) {
      window.registration.update()
    }
  }

  render() {
    let forceCompile = 11

    return (
      <header className="bg-dark">
        <div className="brand">
          <a href="#" onClick={e => {e.preventDefault()}}>
            <img src={logo} alt="" />
          </a>
          <br/>
          <small className={classNames({'text-muted': window.environment != 'staging', 'text-danger': window.environment == 'staging'})}>{APP_VERSION}</small>
        </div>

        <div className="nav nav-technician">
          <a href="#" onClick={this.changePage.bind(this, 'planner')} className={classNames({'active': this.props.master.state.page == 'planner'})}>
            <i className="far fa-calendar-alt"></i><span>Kalendář</span>
          </a>
          <a href="#" onClick={this.changePage.bind(this, 'contracts')} className={classNames({'active': this.props.master.state.page == 'contracts'})}>
            <i className="fas fa-tasks"></i><span>Moje zakázky</span>
          </a>
          <a href="#" onClick={this.changePage.bind(this, 'installations')} className={classNames({'active': this.props.master.state.page == 'installations', 'd-none': !this.props.master.state.user.action_types?.length > 0})}>
            <i className="fas fa-tasks"></i><span>Zakázky</span>
          </a>
          <a href="#" onClick={this.changePage.bind(this, 'contracts-to-sync')} className={classNames({'d-none': !this.props.master.state.contractsToSync.length, 'active': this.props.master.state.page == 'contracts-to-sync'})}>
            <i className="fas fa-tasks"></i><span>Neuložené zakázky</span><span className="counter">{this.props.master.state.contractsToSync.length}</span>
          </a>
          <a href="#" onClick={this.changePage.bind(this, 'stats')} className={classNames({'active': this.props.master.state.page == 'stats'})}>
            <i className="fas fa-tasks"></i><span>Doklady</span>
          </a>
          <a href="#" onClick={this.changePage.bind(this, 'inventory')} className={classNames({'active': this.props.master.state.page == 'inventory'})}>
            <i className="fas fa-warehouse"></i><span>Sklad</span>
          </a>
          <a href="#" onClick={this.changePage.bind(this, 'time-stats')} className={classNames({'active': this.props.master.state.page == 'time-stats'})}>
            <i className="fas fa-time"></i><span>Čas</span>
          </a>
          <a href="#" onClick={this.changePage.bind(this, 'manufacturers-docs')} className={classNames({'active': this.props.master.state.page == 'manufacturers-docs'})}>
            <i className="fas fa-book"></i><span>Dokumentace</span>
          </a>
          <a href="#" onClick={this.props.master.handleLogout.bind(this.props.master)} className="sign-out">
            <i className="fas fa-sign-out-alt"></i><span>Odhlásit se</span>
          </a>
        </div>

        <div className="user">
          <a href="#" className="user-link" onClick={this.changePage.bind(this, 'user-form')}>
            <img src={this.props.master.state.user.avatar_url} alt=""/>
            <div className="name-wrapper">
              <div className="name">{this.props.master.state.user.name}</div>
              <small className="role">Technik</small>
            </div>
          </a>

          <a href='#' className="sign-out btn btn-outline-secondary" onClick={this.props.master.handleLogout.bind(this.props.master)}>
            <i className="fas fa-sign-out-alt"></i>
          </a>
        </div>

        <div className="burger mobile-only" onClick={this.props.master.toggleMobileNav}>
          <i className="fas fa-bars"></i>
        </div>

      </header>

    )
  }
}

export default Header
