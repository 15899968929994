import CommonAsyncSelect from './common_async_select'
import Input from './input'
import ReactSelect from 'react-select'
import Select from './select'
import DeviceImages from './device_images'
import MapSelector from './map_selector'

class CustomerFields extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      similiarCustomers: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customer.places.length != this.props.customer.places.length) {
      if (window.initGoogleMaps) window.initGoogleMaps()
    }

    window.whispers()
  }

  componentWillUnmount() {
    window.mountedWhispers.forEach(component => {
      try {
        ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(component).parentNode)
      }
      catch(error) {
        // console.log(error)
      }
    })
  }

  validateUniqueness(e) {
    if (this.props.customer.id) return true

    let value = e.target.value.replace(/\s/g,'')
    let name = e.target.name
    let message = 'již existuje'
    let error = false

    if (value) {
      jQuery.ajax({
        method: 'GET',
        url: '/api/v1/customers',
        data: {
          q: value
        },
        beforeSend: (xhr) => {
          if (this.props.master) {
            xhr.setRequestHeader ("Authorization", "Bearer " + this.props.master.state.accessToken.access_token)
          } else {
            xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
          }
        },
        success: (data) => {
          if (data.results.length) {
            data.results.forEach(i => {
              if (i[name] == value) {
                error = true
              }
            })

            if (error) {
              this.props.customer.errors[name] = [message]
            }
          }
        },
        complete: () => {
          if (
            !error &&
            this.props.customer.errors &&
            this.props.customer.errors[name] &&
            this.props.customer.errors[name].includes(message)
          ) {
            this.props.customer.errors[name] = this.props.customer.errors[name].filter(i => i != message)
          }

          this.forceUpdate()
        }
      })
    } else {
      if (
        this.props.customer.errors &&
        this.props.customer.errors[name] &&
        this.props.customer.errors[name].includes(message)
      ) {
        this.props.customer.errors[name] = this.props.customer.errors[name].filter(i => i != message)
        this.forceUpdate()
      }
    }
  }

  handleNameChange(e) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    let value = e.target.value

    if (!this.props.customer.company) {
      value = [
        this.props.customer.last_name,
        this.props.customer.first_name
      ].join(' ')
    }

    this.searchTimeout = setTimeout(() => {
      jQuery.ajax({
        method: 'GET',
        url: '/api/v1/customers',
        data: {
          q: value.trim(),
          search_scope: 'name'
        },
        beforeSend: (xhr) => {
          if (this.props.master) {
            xhr.setRequestHeader ("Authorization", "Bearer " + this.props.master.state.accessToken.access_token)
          } else {
            xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
          }
        },
        success: (data) => {
          this.setState({
            similiarCustomers: data.results,
            search: value.trim()
          })
        },
        complete: () => {
        }
      })
    }, 500)
  }

  render() {
    let manufacturerOptions
    if (this.props.master) {
      manufacturerOptions = this.props.master.state.manufacturers.map(i => {
        return {label: i.name, value: i.id}
      })
    }

    let places = this.props.customer.places.filter(i => !i._destroy)

    let similiarCustomersError = null
    let searchValue = this.props.customer.name
    if (!this.props.customer.company) {
      searchValue = [
        this.props.customer.last_name,
        this.props.customer.first_name
      ].join(' ')
    }
    if (
      searchValue?.trim() &&
      this.state.similiarCustomers.length &&
      searchValue?.trim() == this.state.search
    ) {
      similiarCustomersError = (
        <span>
          <strong>Upozornění:</strong> nalezeno podobných kontaktů: {this.state.similiarCustomers.length}
        </span>
      )
    }

    return (
      <div>
        <Input
          name="company"
          type="checkbox"
          object={this.props.customer}
          onChange={this.props.handleInputChange.bind(this)}
          autoComplete="new-password"
        />

        <div className={classNames({'d-none': !this.props.customer.company})}>
          <div className="row">
            <div className='col-sm'>
              <div className='row'>
                <div className='col-sm'>
                  <Input
                    name="id_no"
                    type="text"
                    object={this.props.customer}
                    onChange={this.props.handleInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>
                <div className='col-sm-auto'>
                  <a
                    className={classNames('btn btn-outline-secondary btn-ares mb-2', {'disabled': this.props.loadingAres})}
                    href="#"
                    onClick={(e) => this.props.loadAres({e, vatNumber: this.props.customer.id_no})}>
                    Načíst z ARES
                  </a>
                </div>
              </div>
            </div>

            <div className='col-sm'>
              <Input
                name="tax_no"
                type="text"
                object={this.props.customer}
                onChange={this.props.handleInputChange.bind(this)}
                autoComplete="new-password"
              />
            </div>
          </div>

          <Input
            name="vat_payer"
            type="checkbox"
            object={this.props.customer}
            onChange={this.props.handleInputChange.bind(this)}
            autoComplete="new-password"
          />
        </div>

        <div className="row">
          <div className={classNames('col-sm-10', {'d-none': !this.props.customer.company})}>
            <Input
              name="name"
              type="text"
              object={this.props.customer}
              onChange={this.props.handleInputChange.bind(this)}
              label={this.props.customer.company ? 'Název' : 'Jméno a příjmení'}
              autoComplete="new-password"
              onBlur={this.handleNameChange.bind(this)}
              error={similiarCustomersError}
            />
          </div>
          {!this.props.customer.company ? (
            <React.Fragment>
              <div className="col-sm-4">
                <Input
                  name="last_name"
                  type="text"
                  object={this.props.customer}
                  onChange={this.props.handleInputChange.bind(this)}
                  autoComplete="new-password"
                  onBlur={this.handleNameChange.bind(this)}
                  error={similiarCustomersError}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  name="first_name"
                  type="text"
                  object={this.props.customer}
                  onChange={this.props.handleInputChange.bind(this)}
                  onBlur={this.handleNameChange.bind(this)}
                  autoComplete="new-password"
                />
              </div>
              <div className="col-sm-2">
                <Input
                  name="title"
                  type="text"
                  object={this.props.customer}
                  onChange={this.props.handleInputChange.bind(this)}
                  onBlur={this.handleNameChange.bind(this)}
                  autoComplete="new-password"
                />
              </div>
            </React.Fragment>
          ) : null}
          <div className="col-sm-2">
            <Input
              name="custom_id"
              type="number"
              object={this.props.customer}
              onChange={this.props.handleInputChange.bind(this)}
              autoComplete="new-password"
              placeholder={this.props.customer.id}
            />
          </div>
        </div>

        {this.props.customer.company ? (
          <div className="row">
            <div className="col-sm-5">
              <Input
                name="last_name"
                type="text"
                object={this.props.customer}
                onChange={this.props.handleInputChange.bind(this)}
                autoComplete="new-password"
                hint="Kontaktní osoba"
              />
            </div>
            <div className="col-sm-5">
              <Input
                name="first_name"
                type="text"
                object={this.props.customer}
                onChange={this.props.handleInputChange.bind(this)}
                autoComplete="new-password"
              />
            </div>
            <div className="col-sm-2">
              <Input
                name="title"
                type="text"
                object={this.props.customer}
                onChange={this.props.handleInputChange.bind(this)}
                autoComplete="new-password"
              />
            </div>
          </div>
        ) : null}

        <Select
          name="email_status"
          object={this.props.customer}
          onChange={this.props.handleInputChange.bind(this)}
          options={window.helperData.customer.email_statuses}
        />


        <div className={classNames({'d-none': this.props.customer.email_status != 'has'})}>
          {this.props.customer.emails_data.map(i =>
            <div className="row align-items-center mb-3" key={i.uuid}>
              <div className="col">
                <input
                  className="form-control"
                  name="value"
                  type="email"
                  placeholder="E-mail"
                  value={i.value}
                  onChange={this.props.handleEmailPhoneChange.bind(this, i.uuid, 'emails_data')}
                  autoComplete="new-password"
                />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  name="note"
                  type="text"
                  placeholder="Poznámka"
                  value={i.note}
                  onChange={this.props.handleEmailPhoneChange.bind(this, i.uuid, 'emails_data')}
                  autoComplete="new-password"
                />
              </div>
              <div className="col-auto">
                <div className="form-check">
                  <input
                    id={`default_${i.uuid}`}
                    className="form-check-input"
                    type="checkbox"
                    name="default"
                    checked={i.default}
                    onChange={this.props.handleEmailPhoneChange.bind(this, i.uuid, 'emails_data')} />
                  <label className="form-check-label" htmlFor={`default_${i.uuid}`}>
                    Výchozí
                  </label>
                </div>
              </div>
              <div className="col-auto">
                <a
                  href="#"
                  className="btn btn-sm btn-danger"
                  onClick={this.props.addRemoveEmailPhone.bind(this, i.uuid, 'emails_data')}>
                  Odebrat
                </a>
              </div>
            </div>
          )}
          <a
            href="#"
            onClick={this.props.addRemoveEmailPhone.bind(this, 'add', 'emails_data')}
            className="btn btn-secondary mb-3">
            Přidat e-mail
          </a>
        </div>

        <div className={classNames({'d-none': this.props.master && this.props.master.state.user && !this.props.master.state.user.can_see_phones})}>
          <div>
            <label htmlFor="">Telefon</label>
          </div>
          {this.props.customer.phones_data.map(i =>
            <div className="row align-items-center mb-3" key={i.uuid}>
              <div className="col">
                <input
                  className="form-control"
                  name="value"
                  type="text"
                  placeholder="Telefon"
                  value={i.value}
                  onChange={this.props.handleEmailPhoneChange.bind(this, i.uuid, 'phones_data')}
                  autoComplete="new-password"
                />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  name="note"
                  type="text"
                  placeholder="Poznámka"
                  value={i.note}
                  onChange={this.props.handleEmailPhoneChange.bind(this, i.uuid, 'phones_data')}
                  autoComplete="new-password"
                />
              </div>
              <div className="col-auto">
                <div className="form-check">
                  <input
                    id={`default_${i.uuid}`}
                    className="form-check-input"
                    type="checkbox"
                    name="default"
                    checked={i.default}
                    onChange={this.props.handleEmailPhoneChange.bind(this, i.uuid, 'phones_data')} />
                  <label className="form-check-label" htmlFor={`default_${i.uuid}`}>
                    Výchozí
                  </label>
                </div>
              </div>
              <div className="col-auto">
                <a
                  href="#"
                  className="btn btn-sm btn-danger"
                  onClick={this.props.addRemoveEmailPhone.bind(this, i.uuid, 'phones_data')}>
                  Odebrat
                </a>
              </div>
            </div>
          )}
          <a
            href="#"
            onClick={this.props.addRemoveEmailPhone.bind(this, 'add', 'phones_data')}
            className="btn btn-secondary mb-3">
            Přidat telefon
          </a>
        </div>

        <Select
          name="kind"
          object={this.props.customer}
          onChange={this.props.handleInputChange.bind(this)}
          options={window.helperData.customer.kinds}
        />

        <div className={classNames({'d-none': this.props.customer.kind != 'problematic'})}>
          <Input
            label="false"
            placeholder={I18n.t('activerecord.attributes.customer.problematic_note')}
            name="problematic_note"
            type="text"
            object={this.props.customer}
            onChange={this.props.handleInputChange.bind(this)}
          />
        </div>

        <div className={classNames({'d-none': this.props.customer.kind != 'new'})}>
          <Input
            label="false"
            placeholder={I18n.t('activerecord.attributes.customer.new_note')}
            name="new_note"
            type="text"
            object={this.props.customer}
            onChange={this.props.handleInputChange.bind(this)}
          />
        </div>

        <Input
          name="note"
          type="textarea"
          object={this.props.customer}
          onChange={this.props.handleInputChange.bind(this)}
        />

        <fieldset className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={this.props.customer.has_billing_address}
              name="has_billing_address"
              id="has_billing_address"
              onChange={this.props.handleInputChange.bind(this)} />
            <label className="form-check-label" htmlFor="has_billing_address">Fakturační adresa</label>
          </div>
        </fieldset>

        <div className={classNames('billing-address', {'d-none': !this.props.customer.has_billing_address})}>
          <div className='address-wrapper'>
            <div className={classNames({'d-none': this.props.customer.company})}>
              <Input
                name="billing_name"
                type="text"
                object={this.props.customer}
                onChange={this.props.handleInputChange.bind(this)}
                autoComplete="new-password"
              />
            </div>

            <div className='row'>
              <div className='col-sm-6'>
                <Input
                  name="street"
                  type="text"
                  object={this.props.customer}
                  onChange={this.props.handleInputChange.bind(this)}
                  autoComplete="new-password"
                />

              </div>
              <div className='col-sm-2'>
                <Input
                  name="zip"
                  type="text"
                  object={this.props.customer}
                  onChange={this.props.handleInputChange.bind(this)}
                  autoComplete="new-password"
                />
              </div>
              <div className='col-sm-4'>
                <Input
                  name="city"
                  type="text"
                  object={this.props.customer}
                  onChange={this.props.handleInputChange.bind(this)}
                  autoComplete="new-password"
                />
              </div>

            </div>

            <div className={classNames({'d-none': this.props.customer.company})}>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <Input
                        name="billing_id_no"
                        type="text"
                        object={this.props.customer}
                        onChange={this.props.handleInputChange.bind(this)}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className='col-sm-auto'>
                      <a
                        className={classNames('btn btn-outline-secondary btn-ares mb-2', {'disabled': this.props.loadingAres})}
                        href="#"
                        onClick={(e) => this.props.loadAres({e, vatNumber: this.props.customer.billing_id_no, scenario: 'billing_address'})}>
                        Načíst z ARES
                      </a>
                    </div>
                  </div>

                </div>
                <div className="col">
                  <Input
                    name="billing_tax_no"
                    type="text"
                    object={this.props.customer}
                    onChange={this.props.handleInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <Input
                name="vat_payer"
                type="checkbox"
                object={this.props.customer}
                onChange={this.props.handleInputChange.bind(this)}
                autoComplete="new-password"
              />
            </div>
          </div>
        </div>

        <nav className="mb-3">
          <ul className='nav nav-pills contract-customer'>
            <li className='nav-item'>
              <a className="nav-link disabled special" href="#">Místo:</a>
            </li>
            {places.map(place =>
              <li key={place.uuid} className={classNames('nav-item')}>
                <a
                  className={classNames('nav-link', {'active': place.uuid == this.props.activePlaceUUID})}
                  href="#change-place"
                  onClick={this.props.handlePlaceChange.bind(this, place.uuid, place.latitude, place.longitude)}>
                  {place.name || place.street || 'Nové místo'}
                </a>
              </li>
            )}
            <li className='nav-item'>
              <a className="nav-link" href="#" onClick={this.props.handleAddPlace.bind(this)}>Přidat místo</a>
            </li>
          </ul>
        </nav>

        {places.map(place =>
          <div key={place.uuid} className={classNames('tab-pane place', {'d-none': this.props.activePlaceUUID != place.uuid})}>
            <Input
              name="has_ppas"
              type="checkbox"
              object={place}
              onChange={this.props.handlePlaceInputChange.bind(this)}
            />

            <div className='address-wrapper'>
              <div className='row'>
                <div className='col-sm-6'>
                  <Input
                    name="street"
                    id="place_street"
                    type="text"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                    data-google-autocomplete-off="true"
                    whisperScenario="address"
                  />

                </div>

                <div className='col-sm-2'>
                  <Input
                    name="zip"
                    id="place_zip"
                    type="text"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>

                <div className='col-sm-4'>
                  <Input
                    name="city"
                    id="place_city"
                    type="text"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-6'>
                  <Input
                    name="latitude"
                    type="number"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>

                <div className='col-sm-6'>
                  <Input
                    name="longitude"
                    type="number"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>
              </div>

              {place.coordsError ? (
                <p className="invalid-feedback force-display">
                  <strong>Upozornění</strong>: byla změněna adresa, ale nebyly aktualizovány souřadnice. Zkontrolujte mapu.
                </p>
              ) : null}

              <Input
                name="location"
                type="text"
                object={place}
                onChange={this.props.handlePlaceInputChange.bind(this)}
                autoComplete="off"
              />

              {/* <div className="google-map" data-latitude={place.latitude} data-longitude={place.longitude}></div> */}
              <MapSelector latitude={place.latitude} longitude={place.longitude} />
            </div>

            <Input
              name="has_billing_address"
              id="place_has_billing_address"
              type="checkbox"
              object={place}
              onChange={this.props.handlePlaceInputChange.bind(this)}
            />

            <div className={classNames('billing-address', {'d-none': !place.has_billing_address})}>
              <Input
                name="billing_name"
                id="place_billing_name"
                type="text"
                object={place}
                onChange={this.props.handlePlaceInputChange.bind(this)}
                autoComplete="new-password"
              />

              <div className='row'>
                <div className='col-sm-6'>
                  <Input
                    name="billing_street"
                    id="place_billing_street"
                    type="text"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                  />

                </div>
                <div className='col-sm-2'>
                  <Input
                    name="billing_zip"
                    id="place_billing_zip"
                    type="text"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>
                <div className='col-sm-4'>
                  <Input
                    name="billing_city"
                    id="place_billing_city"
                    type="text"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <Input
                        name="billing_id_no"
                        id="place_billing_id_no"
                        type="text"
                        object={place}
                        onChange={this.props.handlePlaceInputChange.bind(this)}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className='col-sm-auto'>
                      <a
                        className={classNames('btn btn-outline-secondary btn-ares mb-2', {'disabled': this.props.loadingAres})}
                        href="#"
                        onClick={(e) => this.props.loadAres({e, vatNumber: place.billing_id_no, placeUUID: place.uuid})}>
                        Načíst z ARES
                      </a>
                    </div>
                  </div>

                </div>
                <div className="col">
                  <Input
                    name="billing_tax_no"
                    id="place_billing_tax_no"
                    type="text"
                    object={place}
                    onChange={this.props.handlePlaceInputChange.bind(this)}
                    autoComplete="new-password"
                  />
                </div>
              </div>

              <Input
                name="billing_vat_payer"
                type="checkbox"
                object={place}
                onChange={this.props.handlePlaceInputChange.bind(this)}
                autoComplete="new-password"
              />
            </div>

            {place.devices.map(i =>
              <div key={i.uuid} className={classNames('card mb-3', {'d-none': i && i.delete})}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="">Výrobce</label>
                      { manufacturerOptions ? (
                        <ReactSelect
                          value={i.manufacturer_id ? {value: i.manufacturer_id, label: i.manufacturer} : null}
                          onChange={this.props.handleDeviceChange.bind(this, i.uuid)}
                          placeholder="Hledat"
                          options={manufacturerOptions}
                        />
                      ) : (
                        <CommonAsyncSelect
                          name="manufacturer_id"
                          value={i.manufacturer_id ? {value: i.manufacturer_id, label: i.manufacturer} : null}
                          url="/api/v1/manufacturers"
                          type="manufacturer"
                          errors={[]}
                          accessToken={this.props.accessToken}
                          handleReactSelectChange={this.props.handleDeviceChange.bind(this, i.uuid)}
                          />
                      )}
                      <small className="badge badge-secondary d-none">
                        {i.uuid.substr(i.uuid.length - 6)}
                      </small>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="">Typ zařízení</label>
                        <input
                          type="text"
                          placeholder="Typ zařízení"
                          className="form-control"
                          name="type"
                          value={i.type}
                          onChange={this.props.handleDeviceChange.bind(this, i.uuid)} />
                      </div>
                    </div>
                    <div className="col">
                      <label htmlFor="">Verze</label>
                      <select
                        className="form-control"
                        name="version"
                        value={i.version}
                        onChange={this.props.handleDeviceChange.bind(this, i.uuid)}>
                        <option value=""></option>
                        <option value="Komín">Komín</option>
                        <option value="Kondenz">Kondenz</option>
                        <option value="Turbo">Turbo</option>
                        <option value="Elektro">Elektro</option>
                        <option value="Tepelné čerpadlo">Tepelné čerpadlo</option>
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label htmlFor="">Záruka do</label>
                      <input
                        type="date"
                        className="form-control"
                        name="warranty_until"
                        value={i.warranty_until || ''}
                        onChange={this.props.handleDeviceChange.bind(this, i.uuid)} />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="">Stáří</label>
                      <input
                        type="number"
                        className="form-control"
                        name="age"
                        value={i.year ? new Date().getFullYear() - i.year : ''}
                        onChange={this.props.handleDeviceChange.bind(this, i.uuid)} />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="">Rok výroby</label>
                      <input
                        type="number"
                        className="form-control"
                        name="year"
                        value={i.year}
                        onChange={this.props.handleDeviceChange.bind(this, i.uuid)} />
                    </div>
                    <div className="col">
                      <label htmlFor="">Poznámka</label>
                      <input
                        type="text"
                        placeholder="např. umístění"
                        className="form-control"
                        name="note"
                        value={i.note}
                        onChange={this.props.handleDeviceChange.bind(this, i.uuid)} />
                    </div>
                  </div>
                  <div className="row mt-3 align-items-center">
                    <div className="col">
                      <div className="form-check">
                        <input
                          id={`active_${i.uuid}`}
                          className="form-check-input"
                          type="checkbox"
                          name="active"
                          checked={i.active}
                          onChange={this.props.handleDeviceChange.bind(this, i.uuid)} />
                        <label className="form-check-label" htmlFor={`active_${i.uuid}`}>
                          Aktivní
                        </label>
                      </div>
                    </div>
                    <div className="col-auto text-muted">
                      {i.uuid ? i.uuid.split('-')[0] : ''}
                    </div>
                    <div className="col-auto">
                      <a
                        href="#"
                        className={classNames("btn btn-sm btn-outline-danger", {'d-none': (i.fixed || i.immortal)})}
                        onClick={this.props.removeDevice.bind(this, i.uuid)}>
                        Odebrat zařízení
                      </a>
                    </div>
                  </div>
                  <DeviceImages
                    pushTop={true}
                    deviceUuid={i.uuid}
                    master={this.props.master} />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-sm-6">
                <a href="#" className={classNames("btn btn-outline-danger mb-4", {'d-none': places.length < 2 || place.immortal})} onClick={this.props.handleRemovePlace.bind(this, place.uuid)} data-confirm="Opravdu odebrat?">Odebrat místo</a>
              </div>
              <div className="col-sm-6 text-right">
                <a href="#" className="btn btn-secondary mb-4" onClick={this.props.addDevice.bind(this)}>Přidat zařízení</a>
              </div>
            </div>

          </div>
        )}
      </div>
    )
  }
}

export default CustomerFields
